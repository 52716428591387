import React from 'react'
import { Link } from 'gatsby'

import  '../styles/global.css'

// Components
import Layout from '../components/Layout'

export default function Home() {
  return (
    <Layout>
      <h1>Infopanel</h1>
      <div className='container'>
        <div className='column'>
          <div>
            <Link to='/ls102' className='btn'>LS 102</Link>
          </div>
        </div>
        <div className='column'>
          <div>
            <Link to='/ls45' className='btn'>LS 45</Link>
          </div>
        </div>
        <div className='column'>
          <div>
            <Link to='/doppelzimmer' className='btn'>Doppelzimmer Timeliner</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
